.App {
  display: flex;
  flex-direction: row;
  height: 99.8vh;
  overflow:hidden;
}

.leftpane{
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: white;
  height: 99.8vh;
}

.menubar{
  width: 100%;
  /* background-color: rgb(117, 207, 240, 1); 
    background-color: rgb(76, 37, 162, 1); */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
}

.menuitem{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid transparent;
  color: rgb(76, 37, 162, 1);
  margin:10px 0px 0px 10px;
  border-radius: 10%;
  cursor: pointer;
  border-radius: 20%;
}

.menuitemselected{
  background-color:whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(76, 37, 162, 1);
  margin:10px 0px 0px 10px;
  border-radius: 20%;
  cursor: pointer;
}

.menuitem:hover{
  background-color:white;
  border: 1px solid rgb(76, 37, 162, 1);
}

.menuitemselected:focus, .menuitem:focus{
  outline: none;
}

.menuItemText{
  font-size: x-small;
  text-align: left;
}

.tabbar{
  width: 75px;
  /* background-color: rgb(117, 207, 240, 1); 
    background-color: rgb(76, 37, 162, 1); */
  height: 87vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
}

.tabitem{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid transparent;
  margin:10px 0px 0px 10px;
  border-radius: 10%;
  cursor: pointer;
  border-radius: 20%;
  color: rgb(74, 133, 231, 1);
}

.tabitemselected{
  background-color:whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(76, 37, 162, 1);
  margin:10px 0px 0px 10px;
  border-radius: 20%;
  cursor: pointer;
  color: rgb(84, 38, 164, 1);
}

.tabitem:hover{
  background-color:white;
  border: 1px solid rgb(76, 37, 162, 1);
  color: rgb(84, 38, 164, 1);
}

.tabitemselected:focus, .tabitem:focus{
  outline: none;
}

.tabitemtext{
  font-size: x-small;
  text-align: left;
}

.menuItemText{
  font-size: x-small;
  text-align: left;
}

.centralpane{
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.titlebar{
  height: 75px;
}

.headerelement{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 75px;
  padding-top: 10px;
  width: 100%;
}

.titleimage{
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
}

.titlepane{
  padding: 0px;
  width: 95%;

}

.titletop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.titlemenu{
  color: rgb(74, 119, 229);
  font-size: x-small;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
}

.titlemenu:hover{
  color: rgb(76, 37, 162, 1);
}

.titletext{
  font-size: x-large;
  color: rgb(76, 37, 162, 1);
}

.titlesubtext{
  font-size: medium;
  padding-left: 2px; 
  color: rgb(74, 119, 229);
}

.detailspane{
  overflow-y: scroll;
  width:100%;
}

.mapcontainer{
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.mapcontainer_stretch{
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.thresholds{
  height: 100%;
  width: 30%;
}

.thresholds_fs{
  height: 100%;
  width: 60%;
}

.mappane{
  height: 100%;
  width: 60%;
}

.mappanenarrow{
  flex:2;
  height: 50%;
  width: 100%;
}


.webmap {
  height: 1000px;
}


.framework{
  width: 100%;
  height: 46%;
}

.cvca_framework{
  width: 100%;
  height: 50%;
}


.framework_fs{
  width: 60%;
  height: 100vh;
}

.matrix{
  background: white;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray; 
}

.orgrefer{
  display:flex;
  direction:row; 
  width: '95%';
}


.force-scroll{
  min-height: 900px;
  padding-left: 10px;
}

.ecosystemdetail{
  display: flex;
  flex-direction: column;
  width: 95%;
}

.stickyheader{
  position:sticky;
  z-index:1;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  background-color: white;
  width: 100%;
}
.ecosystemdetailtitle{
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: x-large;
  font-weight: bold;
  background:white;
}

.simplepanel{
  border: 1px solid lightgray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 2px 8px rgba(27,9,9,.1), 0 0 0 1px rgba(10,10,10,.1);
  margin: 5px;
  
}

.simplepanel .paneltitle{
  border-bottom: 1px groove lightgray;
  padding:5px;
  font-size: large;
  font-weight: bold;
  padding:5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.simplepanel .panelcontent{
  padding:5px;
  text-align: center;
  flex:1;
}

.spatialcontents{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.spatialmetric{
  font-size: xx-large;
  text-align: center;
}

.spatialtext{
  font-size: medium;
  text-align: center;
}

.biodiversitycontent{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: large;
}

.bbutton{
  border-radius: 5px;
  padding: 5px;
  opacity: 0.8;
  border: 0;
  cursor: pointer;
}

.breadcrumbbutton{
  font-family: 'Abhaya Libre', serif!important;
  border: 0;
  cursor: pointer;
  font-size: large;
}

.breadcrumbbutton:focus, .breadcrumbbutton:focus{
  outline: none;
  opacity: 0.8;
}

.breadcrumbbutton:hover{
  opacity: 0.8;
}

.bbutton1{
  border-radius: 5px;
  padding: 5px;
  opacity: 0.8;
  border: 0;
  cursor: pointer;
  width: 60px;
  transition-duration: 0.4s;
}

.bbutton1:after{
  transition: all 0.8s
}


.bbutton:hover{
  opacity: 1;
}

.bbutton:focus, .bbutton:focus{
  outline: none;
}

.healthcontents{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.imgcontents{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;  
}

.filmstrip{
  min-width: 250px;
  overflow-x : scroll;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  width: 98%;
}

.fieldimage{
  padding: 10px;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ListItemEven {
  background-color: #f8f8f0;
}


.listpanelbuttonbar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.listpanelbuttonbar .bbutton{
  margin: 10px;
  width: 100px;
  height: 40px;
  font-size: small;
}

.regionpanelbuttonbar{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.regionpanelbuttonbar .bbutton{
  margin: 2px;
  font-size: small;
}


@media (max-width:420px){
  .App {
    flex-direction: column;
  }
  
  .leftpane {
    width: 100%
  }

  .orgrefer{
    display:flex;
    flex-direction:column; 
    width: 98%;
  }

  .simplepanel .panelcontent {
    text-align:left;
  }

  .mappane{
    visibility: hidden;
    display: none;
  }

  .maponmobile{
    height: 300px;
    width: 98%;
  }
}

.MuiTypography-root, .MuiButton-label, .MuiFormLabel-root{
  font-family: 'Abhaya Libre', serif!important;
}

.MuiTypography-body1{
  font-size: small!important;
}

.MuiTypography-colorTextSecondary{
  color: red!important;
}

.MuiDialogTitle-root{
  padding-bottom: 5px!important;
}

